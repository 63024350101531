<template>
  <div class="container">
    <el-container>
      <el-header >
        <el-row type="flex" :gutter="10" style="width: 100vw">
          <el-col :span="3">
            <el-input
              v-model="filterOption.name"
              :placeholder="$t('customer.name')"
              size="mini"
            ></el-input>
          </el-col>

          <el-col :span="4" :offset="17">
            <div style="float: right">
              <el-button type="primary" size="mini" @click="doSearch">{{
                $t("system.search")
              }}</el-button>
              <el-button type="warning" size="mini" @click="doReset">{{
                $t("system.reset")
              }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="tableHeader">
          <el-button size="mini" @click="showCreateDialog">{{
            $t("system.create")
          }}</el-button>
        </div>
        <el-table
          :data="tableData"
          ref="customerTable"
          border
          :height="tHeight"
          v-loading="isTableLoading"
          @selection-change="onTableSelectionChange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column type="selection" width="40" fixed></el-table-column>
          <el-table-column
            type="index"
            :label="$t('system.index')"
            width="60"
            align="center"
            fixed
          ></el-table-column>
          <el-table-column
            prop="companyCode"
            :label="$t('customer.companyCode')"
            align="center"
            show-overflow-tooltip
             width="150"
          ></el-table-column>
          <el-table-column
            prop="companyNameCn"
            :label="$t('customer.companyNameCn')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="companyNameEn"
            :label="$t('customer.companyNameEn')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('customer.isActive')"
            align="center"
            width="100"
            show-overflow-tooltip
          >
            <template #default="scope">
              <span>
                <el-tag v-if="scope.row.activeFlag == 1" type="success">{{
                  $t("system.enable")
                }}</el-tag>
                <el-tag v-else type="danger">{{ $t("system.disable") }}</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="countryNameCn"
            :label="$t('customer.countryNameCn')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="countryNameEn"
            :label="$t('customer.countryNameEn')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="provinceNameCn"
            :label="$t('customer.provinceNameCn')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="provinceNameEn"
            :label="$t('customer.provinceNameEn')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="cityNameCn"
            :label="$t('customer.cityNameCn')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="cityNameEn"
            :label="$t('customer.cityNameEn')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="address"
            :label="$t('customer.address')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="postcode"
            :label="$t('customer.postcode')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="mobile"
            :label="$t('customer.mobile')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="organization"
            :label="$t('customer.organization')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="settlementMethod"
            :label="$t('customer.settlementMethod')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="closing"
            :label="$t('customer.closing')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="email"
            :label="$t('customer.email')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="mobile"
            :label="$t('customer.mobile')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="sale"
            :label="$t('customer.sale')"
            align="center"
            width="120"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="customerService"
            :label="$t('customer.customerService')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="type"
            :label="$t('customer.type')"
            align="center"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="creditLimit"
            :label="$t('customer.creditLimit')"
            align="center"
            width="200"
            show-overflow-tooltip
          ></el-table-column>

          <el-table-column
            :label="$t('system.operation')"
            fixed="right"
            align="center"
            width="100"
          >
            <template #default="scope">
              <el-button
                type="text"
                size="mini"
                icon="el-icon-edit"
                @click="edit(scope.row)"
                >{{ $t("system.edit") }}</el-button
              >
              
            </template>
          </el-table-column>
        </el-table>
        <div style="float: right; margin-top: 10px">
          <el-pagination
            @size-change="onTableSizeChange"
            @current-change="onTablePageChange"
            :current-page="filterOption.pageNo"
            :page-sizes="[10, 50, 100, 200]"
            layout="total,sizes,prev, pager, next"
            :total="filterOption.total"
          ></el-pagination>
        </div>
      </el-main>
    </el-container>

    <create-customer-dialog
      v-if="isShowCreateDialog"
      :isShowDialog="isShowCreateDialog"
      @onDialogClose="closeCreateDialog"
    />
    <update-customer-dialog
      v-if="isShowUpdateDialog"
      :isShowDialog="isShowUpdateDialog"
      :rowInfo="currentRow"
      @onDialogClose="closeUpdateDialog"
    />
  </div>
</template>

<script>
import partnerServer from "./source/partner.js";
import mixin from "@/utils/mixin.js";
import CreateCustomerDialog from "./dialogs/createCustomer";
import UpdateCustomerDialog from "./dialogs/updateCustomer";
export default {
  name: "customer",
  components: {
    CreateCustomerDialog,
    UpdateCustomerDialog,
  },
  mixins: [mixin],
  data() {
    return {
      isTableLoading: false,
      isShowCreateDialog: false,
      isShowUpdateDialog: false,
      filterOption: {
        code: null,
        name: null,
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },



      tableData: [],
      tableSelection: [],
      currentRow: null,
    };
  },
  computed: {
    tHeight() {
      return this.tableHeight - 135;
    },
  },
  mounted() {},
  methods: {
    doSearch() {
      partnerServer
        .queryCustomer({
          ...this.filterOption,
          current: this.filterOption.pageNo,
          size: this.filterOption.pageSize,
        })
        .then((res) => {
          this.tableData = res.data ? res.data.records : [];
          this.filterOption.total = res.data.total;
        });
    },
    doReset() {
      this.filterOption = {
        code: null,
        name: null,

        pageNo: 1,
        pageSize: 10,
        total: 0,
      };
    },
    edit(row) {
      this.currentRow = row;
      this.isShowUpdateDialog = true;
    },

    onTableSelectionChange(val) {
      this.tableSelection = val;
    },
    onTableSizeChange(size) {
      this.filterOption = { ...this.filterOption, pageSize: size, pageNo: 1 };
      this.doSearch();
    },
    onTablePageChange(page) {
      this.filterOption = { ...this.filterOption, pageNo: page };
      this.doSearch();
    },
    showCreateDialog() {
      this.isShowCreateDialog = true;
    },
    closeCreateDialog() {
      this.isShowCreateDialog = false;
    },
    closeUpdateDialog() {
      this.isShowUpdateDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: calc(100% - 20px);
  background-color: white;
  overflow-y: scroll;
  padding: 10px;

  .el-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 10px;
    :deep(el-form-item) {
      margin-right: 10px;
    }
  }

  :deep(.el-main) {
    padding: 0 20px;
    .tableHeader {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 48px;
    }
  }
}
</style>
