import request from "@/api/request";

const partnerServer = {
  queryCustomer: (params) =>
    request({
      url: "/customer/queryCustomer",
      method: "get",
      params: params,
    }),

  addCustomer: (params) => request.post("/customer/addCustomer", params),

  updateCustomer: (params) => request.post("/customer/updateCustomer", params),

  queryContract: (params) => {
    let url = "/contract/queryContract?";
    Object.keys(params).map((key) => {
      if (params[key]) url += `${key}=${params[key]}&`;
    });
    return request.post(url, {});
  },

  addContract: (params) => request.post("/contract/addContract", params),

  updateContract: (params) => request.post("/contract/updateContract", params),
};

export default partnerServer;
