<template>
  <el-dialog
    :title="$t('customer.createCustomer')"
    v-model="isShow"
    width="1000px"
    :before-close="handleClose"
  >
    <el-form
      ref="customerForm"
      :inline="true"
      :model="customerInfo"
      size="mini"
      :rules="customerRules"
    >
      <div class="row">
        <el-form-item
          :label="$t('customer.companyCode')"
          prop="companyCode"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.companyCode"
            :placeholder="$t('system.pleaseInput') + $t('customer.companyCode')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('customer.companyNameCn')"
          prop="companyNameCn"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.companyNameCn"
            :placeholder="
              $t('system.pleaseInput') + $t('customer.companyNameCn')
            "
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('customer.companyNameEn')"
          prop="companyNameEn"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.companyNameEn"
            :placeholder="
              $t('system.pleaseInput') + $t('customer.companyNameEn')
            "
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('customer.type')"
          prop="type"
          label-width="130px"
        >
          <el-select
            filterable
            v-model="customerInfo.type"
            multiple
            collapse-tags
            :placeholder="$t('system.pleaseSelect') + $t('customer.type')"
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in companyTypeOptions"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('customer.settlementMethod')"
          prop="settlementMethod"
          label-width="130px"
        >
          <el-select
            filterable
            v-model="customerInfo.settlementMethod"
            :placeholder="
              $t('system.pleaseSelect') + $t('customer.settlementMethod')
            "
            clearable
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in settlementOptions"
              :key="index"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('customer.creditLimit')"
          prop="creditLimit"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.creditLimit"
            :placeholder="$t('system.pleaseInput') + $t('customer.creditLimit')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('customer.country')"
          prop="country"
          label-width="130px"
        >
          <el-select
            v-model="customerInfo.country"
            filterable
            remote
            :remote-method="fetchCountry"
            :loading="loadingCountry"
            :placeholder="$t('system.inputAndSearch')"
            @change="onChangeCountry"
          >
            <el-option
              v-for="(item, index) in countryList"
              :key="item.value"
              :label="item.label"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('customer.province')"
          prop="province"
          label-width="130px"
        >
          <el-select
            v-model="customerInfo.province"
            filterable
            remote
            :remote-method="fetchProvince"
            :loading="loadingProvince"
            :placeholder="$t('system.inputAndSearch')"
            @change="onChangeProvince"
          >
            <el-option
              v-for="(item, index) in provinceList"
              :key="item.value"
              :label="item.label"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('customer.city')"
          prop="city"
          label-width="130px"
        >
          <el-select
            v-model="customerInfo.city"
            filterable
            remote
            :remote-method="fetchCity"
            :loading="loadingCity"
            :placeholder="$t('system.inputAndSearch')"
            @change="onChangeCity"
          >
            <el-option
              v-for="(item, index) in cityList"
              :key="item.value"
              :label="item.label"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('customer.postcode')"
          prop="postcode"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.postcode"
            :placeholder="$t('system.pleaseInput') + $t('customer.postcode')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('customer.address')"
          prop="address"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.address"
            :placeholder="$t('system.pleaseInput') + $t('customer.address')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('customer.mobile')"
          prop="mobile"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.mobile"
            :placeholder="$t('system.pleaseInput') + $t('customer.mobile')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('customer.sale')"
          prop="sale"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.sale"
            :placeholder="$t('system.pleaseInput') + $t('customer.sale')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('customer.customerService')"
          prop="customerService"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.customerService"
            :placeholder="
              $t('system.pleaseInput') + $t('customer.customerService')
            "
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('customer.closing')"
          prop="closing"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.closing"
            :placeholder="$t('system.pleaseInput') + $t('customer.closing')"
            size="mini"
          ></el-input>
        </el-form-item>
      </div>
      <div class="row">
        <el-form-item
          :label="$t('customer.isActive')"
          prop="isActive"
          label-width="130px"
        >
          <el-select
            v-model="customerInfo.isActive"
            :placeholder="$t('system.inputAndSearch')"
          >
            <el-option :label="$t('system.yes')" :value="1"> </el-option>
            <el-option :label="$t('system.no')" :value="0"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('customer.email')"
          prop="email"
          label-width="130px"
        >
          <el-input
            v-model.trim="customerInfo.email"
            :placeholder="$t('system.pleaseInput') + $t('customer.email')"
            size="mini"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('customer.organization')"
          prop="orgId"
          label-width="130px"
        >
          <el-select
            filterable
            v-model="customerInfo.orgId"
            :placeholder="
              $t('system.pleaseSelect') + $t('customer.organization')
            "
            clearable
          >
            <el-option
              :label="item.companyNameCn"
              :value="'' + item.id"
              v-for="(item, index) in organizationList"
              :key="index"
            >
              <span style="float: left">{{ item.companyNameCn }}</span>
              <span
                style="
                  float: right;
                  color: 'var(--el-text-color-secondary)';
                  font-size: 12px;
                "
                >{{ item.companyCode }}</span
              ></el-option
            >
          </el-select>
        </el-form-item>
      </div>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" size="mini">{{
          $t("system.cancel")
        }}</el-button>
        <el-button type="primary" @click="doCreateCustomer" size="mini">{{
          $t("system.confirm")
        }}</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import partnerServer from "../source/partner";
export default {
  props: ["isShowDialog"],
  emits: ["onDialogClose"],
  data() {
    return {
      customerInfo: {
        companyCode: null,
        companyNameCn: null,
        companyNameEn: null,
        type: [],
        settlementMethod: null,
        creditLimit: null,
        country: null,
        province: null,
        city: null,
        postcode: null,
        address: null,
        mobile: null,
        sale: null,
        customerService: null,
        closing: null,
        isActive: 1,
        email: null,
        orgId: null,
      },
      customerRules: {
        companyCode: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("customer.companyCode"),
            trigger: "blur",
          },
        ],
        companyNameCn: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("customer.companyNameCn"),
            trigger: "blur",
          },
        ],
        companyNameEn: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("customer.companyNameEn"),
            trigger: "blur",
          },
        ],
        country: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("customer.country"),
            trigger: "change",
          },
        ],
        province: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("customer.province"),
            trigger: "change",
          },
        ],
        city: [
          {
            required: true,
            message: this.$t("system.pleaseSelect") + this.$t("customer.city"),
            trigger: "change",
          },
        ],
        settlementMethod: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") +
              this.$t("customer.settlementMethod"),
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: this.$t("system.pleaseSelect") + this.$t("customer.type"),
            trigger: "change",
          },
        ],
        creditLimit: [
          {
            required: true,
            message:
              this.$t("system.pleaseInput") + this.$t("customer.creditLimit"),
            trigger: "blur",
          },
        ],
        orgId: [
          {
            required: true,
            message:
              this.$t("system.pleaseSelect") + this.$t("customer.organization"),
            trigger: "change",
          },
        ],
      },
      // organizationList: [
      //   { id: 1, name: "壹站供应链管理有限公司" },
      //   { id: 2, name: "上海道骊物流有限公司" },
      // ],
      settlementOptions: [
        { id: 1, name: "充值" },
        { id: 2, name: "月结" },
      ],
      countryList: [],
      loadingCountry: false,
      provinceList: [],
      loadingProvince: false,
      cityList: [],
      loadingCity: false,

      companyTypeOptions: [
        { id: "isCustomer", name: "客户" },
        { id: "isCustomsBroker", name: "报关行" },
        { id: "isFinalAgent", name: "尾程代理" },
        { id: "isFreightForwarding", name: "货运代理" },
        { id: "isInternalExpressCompany", name: "国内快递公司" },
        { id: "isOverseasCustomsClearance", name: "海外清关行" },
      ],
    };
  },
  computed: {
    organizationList(){
      return this.$store.state.constants.saleCompanys;
    },
    isShow() {
      return this.isShowDialog;
    },
  },
  methods: {
    getSubmitParam() {
      this.companyTypeOptions.forEach((item) => {
        delete this.customerInfo[item.id];
      });

      if (this.customerInfo.type && this.customerInfo.type.length) {
        this.customerInfo.type.forEach((item) => {
          this.customerInfo[item] = true;
        });
      }
      console.log(this.customerInfo);
      return this.customerInfo;
    },

    doCreateCustomer() {
      this.$refs["customerForm"].validate((valid) => {
        if (valid) {
          partnerServer.addCustomer(this.getSubmitParam()).then(() => {
            this.$confirm(
              this.$t("customer.createSuccess"),
              this.$t("system.continue") + "?",
              {
                confirmButtonText: this.$t("system.confirm"),
                cancelButtonText: this.$t("system.cancel"),
                type: "success",
              }
            )
              .then(() => {
                this.$refs["customerForm"].resetFields();
              })
              .catch(() => {
                this.handleClose();
              });
          });
        }
      });
    },
    handleClose() {
      this.$emit("onDialogClose");
    },
    fetchCountry(query) {
      if (query !== "") {
        this.countryList = [
          { value: 1, label: "中国", nameCn: "中国", nameEn: "china" },
        ];
      } else {
        this.countryList = [];
      }
    },
    onChangeCountry(index) {
      this.customerInfo = {
        ...this.customerInfo,
        countryId: this.countryList[index].value,
        countryNameCn: this.countryList[index].nameCn,
        countryNameEn: this.countryList[index].nameEn,
      };

      console.log(this.customerInfo);
    },
    onChangeProvince(index) {
      this.customerInfo = {
        ...this.customerInfo,
        provinceId: this.provinceList[index].value,
        provinceNameCn: this.provinceList[index].nameCn,
        provinceNameEn: this.provinceList[index].nameEn,
      };

      console.log(this.customerInfo);
    },
    onChangeCity(index) {
      this.customerInfo = {
        ...this.customerInfo,
        cityId: this.cityList[index].value,
        cityNameCn: this.cityList[index].nameCn,
        cityNameEn: this.cityList[index].nameEn,
      };

      console.log(this.customerInfo);
    },
    fetchProvince(query) {
      if (query !== "") {
        this.provinceList = [
          { value: 1, label: "上海市", nameCn: "上海市", nameEn: "Shanghai" },
        ];
      } else {
        this.provinceList = [];
      }
    },
    fetchCity(query) {
      if (query !== "") {
        this.cityList = [
          {
            value: 1,
            label: "上海城区",
            nameCn: "上海城区",
            nameEn: "Shanghai",
          },
        ];
      } else {
        this.cityList = [];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :deep(.el-form-item__label) {
    font-size: 12px;
  }
  :deep(.el-input--mini) {
    max-width: 180px;
  }
}
</style>
